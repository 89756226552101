import {
  addDays,
  addMilliseconds,
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { enGB, uk, es, ru } from 'date-fns/locale';
import { Lang } from '../contexts/locale.context';

export const dateLocaleMap = {
  uk,
  en: enGB,
  es,
  ru,
};

export const getDayTimeRange = (time: number = Date.now()): [number, number] => {
  const startDate = startOfDay(time);
  const endDate = endOfDay(time);

  return [startDate.getTime(), endDate.getTime()];
};

export const get2DaysTimeRange = (time: number = Date.now()): [number, number] => {
  const startDate = startOfDay(addDays(time, -1));
  const endDate = endOfDay(time);

  return [startDate.getTime(), endDate.getTime()];
};

export const getMonthTimeRange = (): [number, number] => {
  const startDate = startOfMonth(new Date());
  const endDate = endOfMonth(new Date());

  return [startDate.getTime(), endDate.getTime()];
};

export const getWeekTimeRange = (): [number, number] => {
  const today = new Date();
  const startDate = addDays(startOfWeek(addDays(today, -1)), 1);
  const endDate = addMilliseconds(addDays(new Date(startDate), 7), -1);

  return [startDate.getTime(), endDate.getTime()];
};

export const get2WeeksTimeRange = (lang: Lang): [number, number] => {
  const today = new Date();
  const startDate = startOfWeek(addDays(today, -7), { locale: dateLocaleMap[lang] });
  const endDate = addMilliseconds(addDays(startDate, 15), -1);

  return [startDate.getTime(), endDate.getTime()];
};

export const getWeekDayLabels = (lang: Lang) => {
  const res: string[] = [];
  const date = startOfWeek(new Date());
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  res.push(format(date, 'EEEEEE', { locale: dateLocaleMap[lang] }));
  date.setDate(date.getDate() + 1);
  return res;
};
