import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://ecdc75f8ed743bc9229657a196c76a21@o4505891276521472.ingest.sentry.io/4505891278094336',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development' && process.env.REACT_APP_ENV_NAME !== 'test',
  beforeSend: (event) => {
    if (typeof window && window?.location?.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

declare global {
  type DbDocument<T> = {
    id: string;
  } & T;
}
