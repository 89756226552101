import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { themeLocaleMap } from './constants/styles';
import { useLocale } from './contexts/locale.context';
import { SnackbarProvider } from './contexts/snackbar.context';
import { dateLocaleMap } from './utils/dates';
import lazyWithRetry from './utils/lazyWithRefresh';

const QR = lazyWithRetry(() =>
  import(/* webpackChunkName: "QR" */ './QR/QR').then((module) => ({
    default: module.default,
  }))
);

const AppRouter = lazyWithRetry(() =>
  import(/* webpackChunkName: "AppRouter" */ './AppRouter').then((module) => ({
    default: module.default,
  }))
);

const RootRouter = () => {
  const { lang } = useLocale();

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            primary: {
              light: '#FF8330',
              main: '#EC5E00',
              dark: '#D94D01',
            },
            secondary: {
              light: '#42a5f5',
              main: '#1976d2',
              dark: '#1565c0',
            },
            background: {
              default: '#EFF0F4',
              paper: '#ffffff',
            },
          },
          shape: {
            borderRadius: 8,
          },
        },
        themeLocaleMap[lang]
      ),
    [lang]
  );

  useEffect(() => {
    if (typeof window === 'object' && typeof window.document?.body?.style === 'object') {
      window.document.body.style.backgroundColor = theme.palette.background.default;
    }
  }, [theme.palette.background.default]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: typeof window === 'object' ? window.innerHeight : '100vh' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocaleMap[lang]}>
          <SnackbarProvider>
            <Suspense fallback={null}>
              <Router>
                <Routes>
                  <Route path="/qr/:cafeSlug/*" element={<QR />} />
                  <Route path="*" element={<AppRouter />} />
                </Routes>
              </Router>
            </Suspense>
          </SnackbarProvider>
        </LocalizationProvider>
      </Box>
    </ThemeProvider>
  );
};

export default RootRouter;
